<template>
  <div class="d-flex align-center">
    <v-avatar
      size="36"
      class="v-avatar-light-bg mr-2"
    >
      {{ avatarText(data.name) }}
    </v-avatar>
    <div>
      <router-link :to="{ name: 'customer-detail', params: { id: data.id } }">
        <span class="primary--text font-medium d-block">{{ data.name }}</span>
      </router-link>
      <span>{{ data.email }}</span>
    </div>
  </div>
</template>
<script>
import { avatarText } from '@core/utils/filter'
import { reactive } from '@vue/composition-api'

export default {
  setup() {
    const data = reactive({})

    return {
      avatarText,
      data,
    }
  },
}
</script>
