import Vue from 'vue'
import { apolloClient } from '@/vue-apollo'
import { archiveCustomer } from '@/graphql/mutations'
import store from '@/store'
import errorHandling from '@/utils/errorHandling'

export default () => {
  const delCustomer = id => new Promise((resolve, reject) => {
    Vue.$dialog({
      title: 'Hapus pelanggan?',
      body: 'Konfirmasi jika anda ingin menghapus pelanggan.',
    }).then(confirm => {
      if (confirm) {
        apolloClient.mutate({
          mutation: archiveCustomer,
          variables: {
            id,
            is_archive: true,
            workspace_id: store.getters.getCurrentWorkspaceId,
          },
        }).then(result => {
          Vue.notify({
            title: 'Sukses',
            text: 'Berhasil menghapus pelanggan!',
          })
          resolve(result)
        }).catch(err => {
          errorHandling(err, 'Hapus Pelanggan')
        })
      } else {
        reject()
      }
    })
  })

  return {
    delCustomer,
  }
}
