<template>
  <v-hover #default="{ hover }">
    <v-card
      class="d-flex flex-column flex-grow-1 pa-4"
      outlined
      @click="$router.push({ name: 'customer-detail', params: { id: customer.id } })"
    >
      <div class="d-flex mb-1 align-start">
        <v-avatar
          size="44"
          class="v-avatar-light-bg mr-2"
        >
          {{ avatarText(customer.name) }}
        </v-avatar>
        <div>
          <span class="text-subtitle-2 font-weight-bold d-block primary--text">{{ customer.name }}</span>
          <span class="text-caption font-medium">{{ customer.phone_number }}</span>
        </div>
        <v-menu
          v-if="!isArchive"
          bottom
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-slide-x-reverse-transition mode="out-in">
              <v-icon
                v-show="hover"
                size="20px"
                v-bind="attrs"
                class="ml-auto mt-2"
                v-on="on"
              >
                {{ icons.mdiDotsVertical }}
              </v-icon>
            </v-slide-x-reverse-transition>
          </template>
          <v-list>
            <router-link :to="{ name: 'customer-detail', params: { id: customer.id } }">
              <v-list-item>Lihat Detail</v-list-item>
            </router-link>
            <v-list-item
              v-if="$can('update', 'Customer')"
              @click="$emit('update', customer.id)"
            >
              Ubah Data
            </v-list-item>
            <v-list-item
              v-if="$can('del', 'Customer')"
              class="error--text"
              @click="$emit('delete', customer.id)"
            >
              Hapus Pelanggan
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          v-else
          rounded
          outlined
          small
          text
          class="ml-auto"
          @click.stop="$emit('restore', customer.id)"
        >
          Pulihkan
        </v-btn>
      </div>
      <span class="text-caption font-medium">{{ customer.description }}</span>
      <span class="text-caption text--disabled">{{ customer.email }}</span>
      <limit-elements
        v-if="customer.type"
        :limit="4"
        :elements="customer.type"
        class="mt-2"
      >
        <template #default="{ data }">
          <v-chip
            x-small
            class="mr-1 mb-1"
            :color="data.color"
          >
            {{ data.name }}
          </v-chip>
        </template>
        <template #others="{ data }">
          <v-chip
            x-small
            class="my-1"
            :color="data.color"
          >
            {{ data.name }}
          </v-chip>
        </template>
        <template #others-activator="{ data }">
          <v-chip
            x-small
            class="mt-n3"
          >
            + {{ data.limit }} lainnya
          </v-chip>
        </template>
      </limit-elements>
    </v-card>
  </v-hover>
</template>

<script>
import { avatarText } from '@core/utils/filter'
import { mdiDotsVertical } from '@mdi/js'
import LimitElements from '@/components/misc/LimitElements.vue'

export default {
  components: {
    LimitElements,
  },
  props: {
    customer: {
      type: Object,
      default: () => {},
      required: true,
    },
    isArchive: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      avatarText,

      icons: {
        mdiDotsVertical,
      },
    }
  },
}
</script>

<style>

</style>
