import { ref } from '@vue/composition-api'
import { apolloClient } from '@/vue-apollo'
import {
  savedViews,
} from '@/graphql/queries'
import {
  selectSavedView,
  addSavedView,
  deleteSavedView,
  updateSavedView,
  selectDefaultSavedView,
  addDefaultSavedView,
  deleteSavedDefaultView,
  updateDefaultSavedView,
  setAsDefaultSavedView,
} from '@/graphql/mutations'
import store from '@/store'
import errorHandling from '@/utils/errorHandling'
import Vue from 'vue'

const useSavedView = ({ objectTypeId } = {}) => {
  const loadingFetch = ref(false)
  const loadingEditView = ref(false)
  const loadingAddView = ref(false)

  const listSavedViews = ref([])
  const listSavedDefaultViews = ref([])

  const fetchSavedViews = async () => {
    loadingFetch.value = true
    await apolloClient
      .query({
        query: savedViews,
        variables: {
          workspace_id: store.getters.getCurrentWorkspaceId,
          object_type_id: objectTypeId,
        },
        fetchPolicy: 'no-cache',
      })
      .then(async result => {
        loadingFetch.value = false
        listSavedViews.value = result.data.savedViews.saved_view
        listSavedDefaultViews.value = result.data.savedViews.saved_default_view
      })
      .catch(err => {
        loadingFetch.value = false
        errorHandling(err)
      })
  }

  const switchView = async id => {
    await apolloClient.mutate({
      mutation: selectSavedView,
      variables: {
        saved_view_id: id,
      },
    }).then(result => {
      loadingEditView.value = false
      Vue.notify({
        title: 'Sukses!',
        text: result.data.selectSavedView.msg,
      })
    }).catch(err => {
      loadingEditView.value = false
      errorHandling(err)
    })
  }

  const switchDefaultView = async id => {
    await apolloClient.mutate({
      mutation: selectDefaultSavedView,
      variables: {
        saved_view_id: id,
      },
    }).then(result => {
      loadingEditView.value = false
      Vue.notify({
        title: 'Sukses!',
        text: result.data.selectDefaultSavedView.msg,
      })
    }).catch(err => {
      loadingEditView.value = false
      errorHandling(err)
    })
  }

  const addNewView = async (columns, name) => {
    await apolloClient.mutate({
      mutation: addSavedView,
      variables: {
        name,
        fields: columns,
        workspace_id: store.getters.getCurrentWorkspaceId,
        object_type_id: objectTypeId,
      },
    }).then(result => {
      loadingAddView.value = false
      Vue.notify({
        title: 'Sukses!',
        text: result.data.addSavedView.msg,
      })
    }).catch(err => {
      loadingAddView.value = false
      errorHandling(err)
    })
  }

  const addNewDefaultView = async id => {
    await apolloClient.mutate({
      mutation: addDefaultSavedView,
      variables: {
        saved_view_id: id,
        workspace_id: store.getters.getCurrentWorkspaceId,
      },
    }).then(result => {
      loadingAddView.value = false
      Vue.notify({
        title: 'Sukses!',
        text: result.data.addDefaultSavedView.msg,
      })
    }).catch(err => {
      loadingAddView.value = false
      errorHandling(err)
    })
  }

  const setDefaultView = async id => {
    await apolloClient.mutate({
      mutation: setAsDefaultSavedView,
      variables: {
        saved_view_id: id,
        workspace_id: store.getters.getCurrentWorkspaceId,
      },
    }).then(result => {
      loadingAddView.value = false
      Vue.notify({
        title: 'Sukses!',
        text: result.data.setAsDefaultSavedView.msg,
      })
    }).catch(err => {
      loadingAddView.value = false
      errorHandling(err)
    })
  }

  const editView = async (headers, name, id) => {
    await apolloClient.mutate({
      mutation: updateSavedView,
      variables: {
        name,
        saved_view_id: id,
        fields: headers,
      },
    }).then(result => {
      loadingAddView.value = false
      Vue.notify({
        title: 'Sukses!',
        text: result.data.updateSavedView.msg,
      })
    }).catch(err => {
      loadingAddView.value = false
      errorHandling(err)
    })
  }

  const editDefaultView = async (headers, name, id) => {
    await apolloClient.mutate({
      mutation: updateDefaultSavedView,
      variables: {
        name,
        saved_view_id: id,
        fields: headers,
      },
    }).then(result => {
      loadingAddView.value = false
      Vue.notify({
        title: 'Sukses!',
        text: result.data.updateDefaultSavedView.msg,
      })
    }).catch(err => {
      loadingAddView.value = false
      errorHandling(err)
    })
  }

  const deleteView = async id => {
    await apolloClient.mutate({
      mutation: deleteSavedView,
      variables: {
        saved_view_id: id,
      },
    }).then(result => {
      loadingEditView.value = false
      Vue.notify({
        title: 'Sukses!',
        text: result.data.deleteSavedView.msg,
      })
    }).catch(err => {
      loadingEditView.value = false
      errorHandling(err)
    })
  }

  const deleteDefaultView = async id => {
    await apolloClient.mutate({
      mutation: deleteSavedDefaultView,
      variables: {
        saved_view_id: id,
        workspace_id: store.getters.getCurrentWorkspaceId,
      },
    }).then(result => {
      loadingEditView.value = false
      Vue.notify({
        title: 'Sukses!',
        text: result.data.deleteSavedDefaultView.msg,
      })
    }).catch(err => {
      loadingEditView.value = false
      errorHandling(err)
    })
  }

  return {
    loadingFetch,
    loadingEditView,
    loadingAddView,
    listSavedViews,
    listSavedDefaultViews,

    fetchSavedViews,
    switchView,
    switchDefaultView,
    addNewView,
    addNewDefaultView,
    editView,
    deleteView,
    deleteDefaultView,
    editDefaultView,
    setDefaultView,
  }
}

export default useSavedView
