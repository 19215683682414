import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"px-5 pt-4",attrs:{"min-width":"320px"}},[(!_vm.editMode)?[_c('div',{staticClass:"mx-2 font-weight-bold"},[_vm._v(" Tampilan Default ")]),_vm._l((_vm.listSavedDefaultViews),function(layout){return _c('div',{key:layout.id,staticClass:"d-flex mx-2 my-5 justify-content-center cursor-pointer",on:{"click":function($event){return _vm.selectDefaultView(layout.id)}}},[(layout.is_selected)?_c(VIcon,{attrs:{"size":"20px"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCheck)+" ")]):_vm._e(),_c('div',{staticClass:"flex-grow-1 overflow-hidden"},[_c('span',{staticClass:"ml-4"},[_vm._v(" "+_vm._s(layout.name)+" ")])]),(layout.is_default)?_c(VChip,{staticClass:"mr-2",attrs:{"small":""}},[_c('em',[_vm._v("default")])]):_vm._e(),_c(VMenu,{staticClass:"text-right",attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"size":"20px"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])]}}],null,true)},[_c(VList,{attrs:{"nav":""}},[_c(VListItem,{on:{"click":function($event){return _vm.setAsDefaultView(layout.id)}}},[_c('span',{staticClass:"text-subtitle-2"},[_vm._v("Set as default view")])]),_c(VDivider,{staticClass:"mb-2"}),_c(VListItem,{on:{"click":function($event){return _vm.editLayout(layout)}}},[_c('span',{staticClass:"text-subtitle-2"},[_vm._v("Edit")])]),_c(VListItem,{on:{"click":function($event){return _vm.deleteDefaultLayout(layout.id)}}},[_c('span',{staticClass:"text-subtitle-2"},[_vm._v("Delete")])])],1)],1)],1)}),_c(VDivider,{staticClass:"mb-2"}),_c('div',{staticClass:"mx-2 font-weight-bold"},[_vm._v(" Tampilan Tersimpan ")]),_vm._l((_vm.listSavedViews),function(layout){return _c('div',{key:layout.id,staticClass:"d-flex mx-2 my-5 justify-content-center cursor-pointer",on:{"click":function($event){return _vm.selectView(layout.id)}}},[(layout.is_selected)?_c(VIcon,{attrs:{"size":"20px"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCheck)+" ")]):_vm._e(),_c('div',{staticClass:"flex-grow-1 overflow-hidden"},[_c('span',{staticClass:"ml-4"},[_vm._v(" "+_vm._s(layout.name)+" ")])]),_c(VMenu,{staticClass:"text-right",attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"size":"20px"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])]}}],null,true)},[_c(VList,{attrs:{"nav":""}},[_c(VListItem,{on:{"click":function($event){return _vm.moveToDefaultView(layout.id)}}},[_c('span',{staticClass:"text-subtitle-2"},[_vm._v("Move to default")])]),_c(VDivider,{staticClass:"mb-2"}),_c(VListItem,{on:{"click":function($event){return _vm.editLayout(layout)}}},[_c('span',{staticClass:"text-subtitle-2"},[_vm._v("Edit")])]),_c(VListItem,{on:{"click":function($event){return _vm.deleteLayout(layout.id)}}},[_c('span',{staticClass:"text-subtitle-2"},[_vm._v("Delete")])])],1)],1)],1)})]:_vm._e(),_c(VCard,{staticClass:"text-center"},[(_vm.listSavedViews.length <= 1)?_c(VCardText,[_c(VIcon,{staticClass:"mb-2",attrs:{"size":"64px"}},[_vm._v(" "+_vm._s(_vm.icons.mdiViewDashboardOutline)+" ")]),_c('div',{staticClass:"text-h6"},[_vm._v(" No Saved View ")]),_c('div',{staticClass:"text-body-2"},[_vm._v(" Save your current view to use it later ")])],1):_vm._e(),_c(VDivider),_c(VCardActions,[_c(VForm,{staticClass:"w-full",attrs:{"lazy-validation":""}},[_c(VTextField,{ref:"savedViewForm",attrs:{"rules":[_vm.required, _vm.lengthValidator(_vm.viewName, 3)],"label":"View Name (wajib diisi)","outlined":"","required":"","dense":""},model:{value:(_vm.viewName),callback:function ($$v) {_vm.viewName=$$v},expression:"viewName"}}),(_vm.editMode)?_c(VBtn,{attrs:{"color":"primary","block":"","disabled":_vm.viewName.length ? false : true},on:{"click":function($event){return _vm.submitEditLayout()}}},[_vm._v(" Simpan Perubahan ")]):_c(VBtn,{attrs:{"color":"primary","block":"","disabled":_vm.viewName.length ? false : true},on:{"click":function($event){return _vm.addNewLayout()}}},[_vm._v(" Save New View ")])],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }