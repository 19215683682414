<template>
  <v-card
    min-width="320px"
    class="px-5 pt-4"
  >
    <template v-if="!editMode">
      <div class="mx-2 font-weight-bold">
        Tampilan Default
      </div>
      <div
        v-for="layout in listSavedDefaultViews"
        :key="layout.id"
        class="d-flex mx-2 my-5 justify-content-center cursor-pointer"
        @click="selectDefaultView(layout.id)"
      >
        <v-icon
          v-if="layout.is_selected"
          size="20px"
        >
          {{ icons.mdiCheck }}
        </v-icon>
        <div class="flex-grow-1 overflow-hidden">
          <span class="ml-4">
            {{ layout.name }}
          </span>
        </div>

        <v-chip
          v-if="layout.is_default"
          small
          class="mr-2"
        >
          <em>default</em>
        </v-chip>

        <v-menu
          class="text-right"
          bottom
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              size="20px"
              v-bind="attrs"
              v-on="on"
            >
              {{ icons.mdiDotsVertical }}
            </v-icon>
          </template>

          <v-list
            nav
          >
            <v-list-item
              @click="setAsDefaultView(layout.id)"
            >
              <span class="text-subtitle-2">Set as default view</span>
            </v-list-item>
            <v-divider
              class="mb-2"
            />
            <v-list-item
              @click="editLayout(layout)"
            >
              <span class="text-subtitle-2">Edit</span>
            </v-list-item>
            <v-list-item @click="deleteDefaultLayout(layout.id)">
              <span class="text-subtitle-2">Delete</span>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <v-divider
        class="mb-2"
      />
      <div class="mx-2 font-weight-bold">
        Tampilan Tersimpan
      </div>
      <div
        v-for="layout in listSavedViews"
        :key="layout.id"
        class="d-flex mx-2 my-5 justify-content-center cursor-pointer"
        @click="selectView(layout.id)"
      >
        <v-icon
          v-if="layout.is_selected"
          size="20px"
        >
          {{ icons.mdiCheck }}
        </v-icon>
        <div class="flex-grow-1 overflow-hidden">
          <span class="ml-4">
            {{ layout.name }}
          </span>
        </div>

        <v-menu
          class="text-right"
          bottom
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              size="20px"
              v-bind="attrs"
              v-on="on"
            >
              {{ icons.mdiDotsVertical }}
            </v-icon>
          </template>

          <v-list
            nav
          >
            <v-list-item
              @click="moveToDefaultView(layout.id)"
            >
              <span class="text-subtitle-2">Move to default</span>
            </v-list-item>
            <v-divider
              class="mb-2"
            />
            <v-list-item
              @click="editLayout(layout)"
            >
              <span class="text-subtitle-2">Edit</span>
            </v-list-item>
            <v-list-item @click="deleteLayout(layout.id)">
              <span class="text-subtitle-2">Delete</span>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </template>
    <v-card class="text-center">
      <v-card-text v-if="listSavedViews.length <= 1">
        <v-icon
          class="mb-2"
          size="64px"
        >
          {{ icons.mdiViewDashboardOutline }}
        </v-icon>
        <div class="text-h6">
          No Saved View
        </div>
        <div class="text-body-2">
          Save your current view to use it later
        </div>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-form
          class="w-full"
          lazy-validation
        >
          <v-text-field
            ref="savedViewForm"
            v-model="viewName"
            :rules="[required, lengthValidator(viewName, 3)]"
            label="View Name (wajib diisi)"
            outlined
            required
            dense
          />
          <v-btn
            v-if="editMode"
            color="primary"
            block
            :disabled="viewName.length ? false : true"
            @click="submitEditLayout()"
          >
            Simpan Perubahan
          </v-btn>
          <v-btn
            v-else
            color="primary"
            block
            :disabled="viewName.length ? false : true"
            @click="addNewLayout()"
          >
            Save New View
          </v-btn>
        </v-form>
      </v-card-actions>
    </v-card>
  </v-card>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { required, lengthValidator } from '@core/utils/validation'
import {
  mdiDotsVertical,
  mdiViewDashboardOutline,
  mdiCheck,
} from '@mdi/js'
import useSavedView from '@/composables/useSavedView'

export default {
  props: {
    savedViews: {
      type: Array,
      default: () => [],
    },
    savedDefaultViews: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const viewName = ref('')
    const editData = ref({})
    const editMode = ref(false)
    const savedViewForm = ref(null)
    const listSavedViews = computed(() => props.savedViews)
    const listSavedDefaultViews = computed(() => props.savedDefaultViews)

    const {
      switchView,
      deleteView,
      setDefaultView,
      switchDefaultView,
      addNewDefaultView,
      deleteDefaultView,
    } = useSavedView({ objectTypeId: 4 })

    const addNewLayout = () => {
      emit('save', viewName.value)
      savedViewForm.value.reset()
      viewName.value = ''
    }

    // TODO: handle submit edit for default saved view
    const submitEditLayout = () => {
      emit('save', viewName.value, editData.value.id)
      emit('restore', listSavedViews.value.find(el => el.is_selected).fields)
      editMode.value = false
      savedViewForm.value.reset()
      viewName.value = ''
    }

    /* eslint-disable no-param-reassign */
    const selectView = async id => {
      await switchView(id)
      listSavedDefaultViews.value.forEach(data => {
        data.is_selected = false
      })
      listSavedViews.value.forEach(data => {
        data.is_selected = data.id === id
      })
      emit('restore', listSavedViews.value.find(el => el.is_selected).fields)
    }

    const deleteLayout = async id => {
      // TODO: implement validation if user delete current view

      await deleteView(id)
      emit('update')

      /* manually update saved views list instead of fetch */
      // listSavedViews.value = listSavedViews.value.filter(data => data.id !== id)
    }

    const editLayout = layout => {
      editMode.value = true
      listSavedViews.value.forEach(data => {
        data.is_selected = data.id === layout.id
      })
      emit('restore', listSavedViews.value.find(el => el.is_selected).fields)
      viewName.value = layout.name
      editData.value.id = layout.id
    }

    // methods for default views
    const selectDefaultView = async id => {
      await switchDefaultView(id)
      listSavedViews.value.forEach(data => {
        data.is_selected = false
      })
      listSavedDefaultViews.value.forEach(data => {
        data.is_selected = data.id === id
      })
      emit('restore', JSON.parse(listSavedDefaultViews.value.find(el => el.is_selected).fields))
    }

    const moveToDefaultView = async id => {
      await addNewDefaultView(id)
      emit('update')
    }

    const setAsDefaultView = async id => {
      await setDefaultView(id)
      emit('update')
    }

    const deleteDefaultLayout = async id => {
      // TODO: implement validation if user delete current view

      await deleteDefaultView(id)
      emit('update')

      /* manually update saved views list instead of fetch */
      // listSavedDefaultViews.value = listSavedDefaultViews.value.filter(data => data.id !== id)
    }
    /* eslint-enable no-param-reassign */

    return {
      listSavedDefaultViews,
      listSavedViews,
      savedViewForm,
      viewName,
      editMode,

      required,
      lengthValidator,

      selectView,
      editLayout,
      deleteLayout,
      addNewLayout,
      submitEditLayout,
      setAsDefaultView,
      selectDefaultView,
      moveToDefaultView,
      deleteDefaultLayout,

      icons: {
        mdiCheck,
        mdiDotsVertical,
        mdiViewDashboardOutline,
      },
    }
  },
}
</script>
