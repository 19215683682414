import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSlideXReverseTransition } from 'vuetify/lib/components/transitions';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VCard,{staticClass:"d-flex flex-column flex-grow-1 pa-4",attrs:{"outlined":""},on:{"click":function($event){return _vm.$router.push({ name: 'customer-detail', params: { id: _vm.customer.id } })}}},[_c('div',{staticClass:"d-flex mb-1 align-start"},[_c(VAvatar,{staticClass:"v-avatar-light-bg mr-2",attrs:{"size":"44"}},[_vm._v(" "+_vm._s(_vm.avatarText(_vm.customer.name))+" ")]),_c('div',[_c('span',{staticClass:"text-subtitle-2 font-weight-bold d-block primary--text"},[_vm._v(_vm._s(_vm.customer.name))]),_c('span',{staticClass:"text-caption font-medium"},[_vm._v(_vm._s(_vm.customer.phone_number))])]),(!_vm.isArchive)?_c(VMenu,{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VSlideXReverseTransition,{attrs:{"mode":"out-in"}},[_c(VIcon,_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}],staticClass:"ml-auto mt-2",attrs:{"size":"20px"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)]}}],null,true)},[_c(VList,[_c('router-link',{attrs:{"to":{ name: 'customer-detail', params: { id: _vm.customer.id } }}},[_c(VListItem,[_vm._v("Lihat Detail")])],1),(_vm.$can('update', 'Customer'))?_c(VListItem,{on:{"click":function($event){return _vm.$emit('update', _vm.customer.id)}}},[_vm._v(" Ubah Data ")]):_vm._e(),(_vm.$can('del', 'Customer'))?_c(VListItem,{staticClass:"error--text",on:{"click":function($event){return _vm.$emit('delete', _vm.customer.id)}}},[_vm._v(" Hapus Pelanggan ")]):_vm._e()],1)],1):_c(VBtn,{staticClass:"ml-auto",attrs:{"rounded":"","outlined":"","small":"","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('restore', _vm.customer.id)}}},[_vm._v(" Pulihkan ")])],1),_c('span',{staticClass:"text-caption font-medium"},[_vm._v(_vm._s(_vm.customer.description))]),_c('span',{staticClass:"text-caption text--disabled"},[_vm._v(_vm._s(_vm.customer.email))]),(_vm.customer.type)?_c('limit-elements',{staticClass:"mt-2",attrs:{"limit":4,"elements":_vm.customer.type},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_c(VChip,{staticClass:"mr-1 mb-1",attrs:{"x-small":"","color":data.color}},[_vm._v(" "+_vm._s(data.name)+" ")])]}},{key:"others",fn:function(ref){
var data = ref.data;
return [_c(VChip,{staticClass:"my-1",attrs:{"x-small":"","color":data.color}},[_vm._v(" "+_vm._s(data.name)+" ")])]}},{key:"others-activator",fn:function(ref){
var data = ref.data;
return [_c(VChip,{staticClass:"mt-n3",attrs:{"x-small":""}},[_vm._v(" + "+_vm._s(data.limit)+" lainnya ")])]}}],null,true)}):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }