<template>
  <div>
    <v-chip
      v-for="tag in data.type"
      :key="tag.id"
      small
      class="mr-2 my-1"
      :color="tag.color"
    >
      {{ tag.name }}
    </v-chip>
  </div>
</template>
<script>
import { reactive } from '@vue/composition-api'

export default {
  setup() {
    const data = reactive({})

    return {
      data,
    }
  },
}
</script>
