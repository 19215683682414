<template>
  <v-dialog
    v-model="isOpen"
    max-width="1000px"
    @input="!$event ? $emit('close') : null"
  >
    <v-card
      class="pa-4"
    >
      <div class="d-flex justify-space-between mb-4">
        <span class="text-h6 font-medium primary--text">Advanced Filter Pelanggan</span>
        <v-icon
          @click="close"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>
      <v-form
        ref="customerFilterBuilder"
        lazy-validation
        @submit.prevent="filter"
      >
        <v-row
          v-for="(filter, index) in customerFilter"
          :key="index"
          class="align-center"
        >
          <v-col
            v-if="index === 0"
            cols="12"
            md="1"
            class="text-center"
          >
            Where
          </v-col>
          <v-col
            v-else
            cols="12"
            md="1"
          >
            <v-select
              v-model="filter.condition"
              append-icon=""
              outlined
              dense
              :items="conditionType"
              :rules="[required]"
              hide-details
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-autocomplete
              v-model="filter.fieldInput"
              placeholder="Filter Field"
              outlined
              dense
              :items="attributes"
              :rules="[required]"
              item-text="name"
              return-object
              hide-details
            />
          </v-col>
          <v-col
            v-if="filter.fieldInput"
            cols="12"
            md="2"
          >
            <v-select
              v-if="filter.fieldInput.name === 'Tag'"
              v-model="filter.operator"
              placeholder="Operator"
              outlined
              dense
              :items="tagOperator"
              :rules="[required]"
              hide-details
            />
            <v-select
              v-else-if="filter.fieldInput.name === 'Kota'"
              v-model="filter.operator"
              placeholder="Operator"
              outlined
              dense
              :items="cityOperator"
              :rules="[required]"
              hide-details
            />
            <v-select
              v-else
              v-model="filter.operator"
              placeholder="Operator"
              outlined
              dense
              :items="filter.fieldInput.data_type.operator"
              item-text="name"
              item-value="value"
              :rules="[required]"
              hide-details
            />
          </v-col>
          <v-col
            v-if="filter.fieldInput && filter.operator !== 'IS SET' && filter.operator !== 'IS NOT SET'"
            cols="11"
            md="5"
          >
            <tag-customer
              v-if="filter.fieldInput.name === 'Tag'"
              v-model="filter.value"
              class="value-input"
            >
              <template #match>
                <v-select
                  v-model="filter.match_type"
                  outlined
                  dense
                  :rules="[required]"
                  :items="matchType"
                  hide-details
                />
              </template>
            </tag-customer>
            <city-picker
              v-else-if="filter.fieldInput.name === 'Kota'"
              v-model="filter.value"
              :rules="[required]"
              :multiple="false"
              :label="'Asal Kota'"
            />
            <v-text-field
              v-else-if="filter.fieldInput.data_type.id === 1"
              v-model="filter.value"
              :label="filter.fieldInput.name"
              :placeholder="'Input ' + filter.fieldInput.name"
              :rules="[required]"
              outlined
              dense
              hide-details
              class="value-input"
            >
              <template
                v-if="filter.operator !== '>' && filter.operator !== '<'"
                #append-outer
              >
                <v-select
                  v-model="filter.match_type"
                  outlined
                  dense
                  :rules="[required]"
                  :items="matchType"
                  hide-details
                />
              </template>
            </v-text-field>
            <v-text-field
              v-else-if="filter.fieldInput.data_type.id === 2"
              v-model="filter.value"
              :label="filter.fieldInput.name"
              :placeholder="'Input ' + filter.fieldInput.name"
              :rules="[required]"
              outlined
              dense
              type="number"
              hide-details
              class="value-input"
            />
            <DateTimePicker
              v-else-if="filter.fieldInput.data_type.id === 3"
              v-model="filter.value"
              :label="filter.fieldInput.name"
              :placeholder="filter.fieldInput.name"
              :rules="[required]"
            />
            <v-select
              v-else-if="filter.fieldInput.data_type.id === 4 || filter.fieldInput.data_type.id === 5"
              v-model="filter.value"
              :items="filter.fieldInput.options"
              :label="filter.fieldInput.name"
              :placeholder="filter.fieldInput.name"
              outlined
              dense
              :multiple="filter.fieldInput.data_type.id === 5"
              :chips="filter.fieldInput.data_type.id === 5"
              item-text="name"
              item-value="id"
              hide-details
              :rules="[required]"
              class="value-input"
            >
              <template
                v-if="filter.operator !== '>' && filter.operator !== '<' && filter.fieldInput.data_type.id === 5"
                #append-outer
              >
                <v-select
                  v-model="filter.match_type"
                  outlined
                  dense
                  :rules="[required]"
                  :items="matchType"
                  hide-details
                />
              </template>
            </v-select>
          </v-col>
          <v-col
            v-if="index !== 0"
            cols="1"
            md="1"
            dense
            align-self="center"
            class="pr-0 pl-1"
          >
            <v-btn
              small
              icon
              plain
              @click="removeFilter(index)"
            >
              <v-icon>
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <div class="d-flex flex-column flex-md-row mt-5 pt-5">
        <v-btn
          class="mb-2 mb-md-0 mr-md-2 mr-md-auto"
          outlined
          @click="addFilter()"
        >
          Tambah Filter
        </v-btn>
        <v-btn
          class="mb-2 mb-md-0 mr-md-2 ml-md-auto"
          outlined
          @click="clearFilter()"
        >
          Reset Filter
        </v-btn>
        <v-btn
          color="primary"
          @click="filter"
        >
          Filter Customer
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiClose } from '@mdi/js'
import { createFieldMapper } from 'vuex-use-fields'
import TagCustomer from '@/components/inputs/TagCustomer.vue'
import DateTimePicker from '@/components/inputs/DateTimePicker.vue'
import { required } from '@core/utils/validation'
import CityPicker from '@/components/inputs/CityPicker.vue'

const useFieldCustomer = createFieldMapper({ getter: 'customer/getField', setter: 'customer/setField' })

export default {
  components: {
    TagCustomer,
    DateTimePicker,
    CityPicker,
  },
  setup(props, { emit }) {
    const isOpen = ref(false)
    const state = {
      ...useFieldCustomer(['customerFilter']),
    }
    const attributes = ref([])
    const operator = ref([])
    const tagOperator = ref([
      'IS',
      'IS NOT',
      'IS SET',
      'IS NOT SET',
    ])
    const cityOperator = ref([
      'IS',
      'IS NOT',
    ])
    const matchType = ref([
      'ANY',
      'ALL',
    ])
    const conditionType = ref([
      'AND',
      'OR',
    ])
    const customerFilter = ref([{}])
    const customerFilterBuilder = ref()

    const show = customAttributesArray => {
      isOpen.value = true
      attributes.value = customAttributesArray.map(ca => ca.custom_attribute)
      attributes.value.push({ name: 'Tag' }, { name: 'Kota' })
      customerFilter.value = state.customerFilter.value.raw_filter.length ? state.customerFilter.value.raw_filter : [{ match_type: 'ANY', condition: 'AND' }]
    }

    const close = () => {
      isOpen.value = false
    }

    const filter = () => {
      if (customerFilterBuilder.value.validate()) {
        state.customerFilter.value.raw_filter = customerFilter.value
        state.customerFilter.value.custom_filter = customerFilter.value.filter(data => (!['Tag', 'Kota'].includes(data.fieldInput.name)))
        state.customerFilter.value.customer_type = customerFilter.value.filter(data => (data.fieldInput.name === 'Tag'))
        state.customerFilter.value.customer_city = customerFilter.value.filter(data => (data.fieldInput.name === 'Kota'))
        isOpen.value = false
        emit('close')
      }
    }

    const clearFilter = () => {
      state.customerFilter.value = {
        customer_city: [],
        custom_filter: [],
        customer_type: [],
        raw_filter: [],
      }
      state.customerFilter.value.count = 0
      customerFilter.value = [{}]
      emit('close')
      isOpen.value = false
      customerFilterBuilder.value.reset()
    }

    const removeFilter = index => {
      customerFilter.value.splice(index, 1)
    }

    const addFilter = () => {
      customerFilter.value.push({ match_type: 'ANY', condition: 'AND' })
    }

    return {
      customerFilterBuilder,
      isOpen,
      state,
      cityOperator,
      tagOperator,
      operator,
      attributes,
      customerFilter,
      matchType,
      conditionType,
      required,
      show,
      close,
      clearFilter,
      filter,
      addFilter,
      removeFilter,
      icons: {
        mdiClose,
      },
    }
  },
}
</script>

<style scoped>
  .value-input >>> .v-input__append-outer {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    max-width: 100px;
  }
</style>
