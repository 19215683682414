<template>
  <v-card
    width="400px"
    class="pa-3 pb-0"
  >
    <div class="d-flex justify-space-between">
      <span class="text-subtitle-2 font-weight-bold primary--text">Diarsipkan</span>
      <input-icon-toggle
        v-model="customerFilter.search"
        class="ms-2"
        :icon="icons.mdiMagnify"
        placeholder="Cari pelanggan diarsipkan"
        @input="onSearch"
        @close="closeSearch"
      />
    </div>
    <div
      id="archivedCustomer"
      v-scroll:#archivedCustomer="listenScrollFetchCustomers"
      class="list mt-3"
    >
      <CustomerCard
        v-for="customer in customerList"
        :key="customer.id"
        class="mb-3"
        :customer="customer"
        is-archive
        @restore="restoreCustomerFunc"
      />
    </div>
  </v-card>
</template>

<script>
import { onMounted, computed, watch } from '@vue/composition-api'
import { mdiMagnify } from '@mdi/js'
// eslint-disable-next-line import/no-extraneous-dependencies
import Vue from 'vue'
import InputIconToggle from '@/components/inputs/InputIconToggle.vue'
import CustomerCard from './CustomerCard.vue'
import useCustomer from '@/composables/useCustomer'
import { archiveCustomer } from '@/graphql/mutations'
import { apolloClient } from '@/vue-apollo'
import store from '@/store'
import errorHandling from '@/utils/errorHandling'

export default {
  components: {
    InputIconToggle,
    CustomerCard,
  },
  props: {
    isArchiveOpen: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const isOpen = computed(() => props.isArchiveOpen)
    const {
      customerList, customerFilter, fetchCustomers, debouncedFetchCustomers, listenScrollFetchCustomers,
    } = useCustomer()

    watch(isOpen, () => {
      customerFilter.value.filter.is_archive = true
      fetchCustomers()
    })

    onMounted(() => {
      customerFilter.value.filter.is_archive = true
      fetchCustomers()
    })

    const closeSearch = () => {
      customerFilter.value.filter.is_archive = true
      customerFilter.value.search = null
      fetchCustomers()
    }

    const onSearch = value => {
      customerFilter.value.search = value
      debouncedFetchCustomers()
    }
    const restoreCustomerFunc = id => {
      Vue.$dialog({
        title: 'Pulihkan pelanggan?',
        body: 'Yakin ingin pulihkan pelanggan?',
      }).then(result => {
        if (result) {
          apolloClient.mutate({
            mutation: archiveCustomer,
            fetchPolicy: 'no-cache',
            variables: {
              id,
              is_archive: false,
              workspace_id: store.getters.getCurrentWorkspaceId,
            },
          }).then(() => {
            emit('update')
            Vue.notify({
              title: 'Sukses!',
              text: 'Berhasil memulihkan pelanggan!',
            })
          }).catch(err => {
            errorHandling(err)
          })
        }
      })
    }

    return {
      listenScrollFetchCustomers,
      onSearch,
      debouncedFetchCustomers,
      customerList,
      customerFilter,
      closeSearch,
      restoreCustomerFunc,
      icons: {
        mdiMagnify,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';

.list {
  @include style-scroll-bar();
  max-height: 60vh;
  overflow-y: scroll;
}
</style>
