<template>
  <v-card>
    <v-row class="mx-2 pt-2">
      <v-col>
        <h3>Daftar Pelanggan</h3>
      </v-col>
      <v-col
        cols="auto"
        class="ms-auto d-flex"
      >
        <v-menu
          :close-on-content-click="false"
          offset-y
          nudge-bottom="8px"
          nudge-left="20px"
          transition="slide-y-reverse-transition"
        >
          <template v-slot:activator="menu">
            <v-tooltip bottom>
              <template v-slot:activator="tooltip">
                <v-icon
                  size="26"
                  v-bind="{ ...menu.attrs, ...tooltip.attrs }"
                  v-on="{ ...menu.on, ...tooltip.on }"
                  @click.stop
                >
                  {{ icons.mdiChevronDown }}
                </v-icon>
              </template>
              <span>Cari pelanggan berdasarkan...</span>
            </v-tooltip>
          </template>
          <v-card>
            <v-card-text>
              Cari Berdasarkan
              <div
                v-for="(field, index) in state.search_field.value"
                :key="index"
              >
                <v-switch
                  v-model="field.value"
                  inset
                  flat
                  dense
                  hide-details
                  :label="field.label"
                  @change="fetchCustomers()"
                />
              </div>
            </v-card-text>
          </v-card>
        </v-menu>
        <input-icon-toggle
          v-model="search"
          class="ms-2 me-3"
          :icon="icons.mdiMagnify"
          placeholder="Cari pelanggan"
          :modal="$vuetify.breakpoint.smAndDown"
          @input="searchCustomer"
          @close="search = ''; dataStateChange({ skip: 0, take: pagination.take })"
        />
        <v-menu
          v-model="isArchiveOpen"
          :close-on-content-click="false"
          offset-y
          nudge-bottom="8px"
          nudge-left="20px"
          transition="slide-y-reverse-transition"
        >
          <template v-slot:activator="menu">
            <v-tooltip bottom>
              <template v-slot:activator="tooltip">
                <v-icon
                  size="26"
                  class="me-3"
                  v-bind="{ ...menu.attrs, ...tooltip.attrs }"
                  v-on="{ ...menu.on, ...tooltip.on }"
                  @click.stop
                >
                  {{ icons.mdiArchive }}
                </v-icon>
              </template>
              <span>Pelanggan dihapus / diarsipkan</span>
            </v-tooltip>
          </template>
          <ArchivedCustomer
            :is-archive-open="isArchiveOpen"
            @update="fetchCustomers()"
          />
        </v-menu>
        <v-icon
          size="26"
          @click="fetchCustomers()"
        >
          {{ icons.mdiRefresh }}
        </v-icon>
      </v-col>
    </v-row>
    <v-row class="mx-2 mb-2">
      <v-col
        cols="12"
        lg="3"
        md="4"
      >
        <v-select
          v-model="state.sort.value"
          :disabled="state.headerSort.value.length !== 0"
          placeholder="Sortir Berdasarkan"
          :items="sortOptions"
          item-text="label"
          return-object
          outlined
          dense
          hide-details
          @change="dataStateChange({ skip: 0, take: pagination.take });"
        />
      </v-col>
      <v-col
        cols="12"
        lg="2"
        md="3"
      >
        <v-badge
          :value="filterCount > 0"
          bordered
          overlap
          class="w-100 cursor-pointer"
          @click.native="$refs.customerFilterBuilder.clearFilter()"
        >
          <template
            #badge
          >
            <v-icon>
              {{ icons.mdiClose }}
            </v-icon>
          </template>
          <v-btn
            outlined
            block
            large
            :text="!filterCount"
            :color="filterCount > 0 ? 'primary' : 'secondary'"
            @click.stop="$refs.customerFilterBuilder.show(customAttributesArray)"
          >
            Advanced Filter {{ filterCount > 0 ? `(${filterCount})` : null }}
          </v-btn>
        </v-badge>
      </v-col>
      <v-col
        cols="12"
        md="auto"
        class="ms-0 ms-md-auto d-flex justify-space-between"
      >
        <!-- Saved Views Menu -->
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          offset-y
          transition="slide-y-reverse-transition"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              size="16px"
              icon
              v-bind="attrs"
              class="mr-3"
              v-on="on"
            >
              <v-icon>{{ icons.mdiPlusCircleOutline }}</v-icon>
            </v-btn>
          </template>
          <customer-saved-view
            ref="customerSavedView"
            :saved-views="listSavedViews"
            :saved-default-views="listSavedDefaultViews"
            @restore="restoreLayout"
            @save="saveLayout"
            @update="fetchSavedViews"
          />
        </v-menu>

        <v-btn-toggle
          v-model="viewTab"
          mandatory
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiTable }}</v-icon>
              </v-btn>
            </template>
            <span>Tampilan Tabel</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiCardText }}</v-icon>
              </v-btn>
            </template>
            <span>Tampilan Kartu</span>
          </v-tooltip>
        </v-btn-toggle>
        <v-tooltip
          v-if="$can('create', 'Customer')"
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <split-button
              class="ms-auto ms-md-4"
              color="primary"
              @click="$refs.customerForm.show()"
            >
              <v-icon
                v-bind="attrs"
                v-on="on"
              >
                {{ icons.mdiPlus }}
              </v-icon>
              <template #list>
                <v-list-item @click="$refs.importCustomer.show()">
                  Import Data Pelanggan
                </v-list-item>
              </template>
            </split-button>
          </template>
          <span>Tambah Pelanggan</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-tabs-items
      v-model="viewTab"
      class="rounded"
    >
      <v-tab-item class="grid-container">
        <ejs-grid
          v-if="customerList"
          id="customerGrid"
          ref="customerGrid"
          :columns="columns"
          :toolbar="toolbarOptions"
          :data-source="customerList"
          :edit-settings="editSettings"
          :page-settings="pageSettings"
          :toolbar-click="handleToolbarClick"
          :allow-paging="true"
          :allow-sorting="true"
          :allow-multi-sorting="true"
          :allow-multi-grouping="true"
          :allow-grouping="true"
          :allow-resizing="true"
          :allow-reordering="true"
          :allow-pdf-export="true"
          :allow-excel-export="true"
          :show-column-chooser="true"
          :data-bound="dataBound"
          :action-complete="handleActionComplete"
          :data-state-change="dataStateChange"
          :action-begin="handleActionBegin"
          :pdf-query-cell-info="pdfQueryCellInfo"
          :context-menu-items="contextMenuItems"
          :group-settings="groupSettings"
        />
      </v-tab-item>
      <v-tab-item class="mx-4">
        <v-data-iterator
          :headers="headers"
          :items="state.customers.value"
          item-key="id"
          show-select
          disable-sort
          :footer-props="{
            'items-per-page-options': [21, 40, 80],
            'items-per-page-text': 'Jumlah data',
          }"
          :server-items-length="state.customersCount.value"
          mobile-breakpoint="0"
          :loading="loading"
          :options.sync="pagination"
        >
          <template v-slot:default="props">
            <v-row class="match-height">
              <v-col
                v-for="item in props.items"
                :key="item.id"
                cols="12"
                sm="6"
                md="4"
                xl="3"
              >
                <customer-card
                  :customer="item"
                  @update="$refs.customerForm.update(item.id)"
                  @delete="deleteCustomer(item.id)"
                />
              </v-col>
            </v-row>
          </template>
          <template
            v-slot:[`footer.page-text`]="page"
          >
            Melihat {{ page.pageStart }} - {{ page.pageStop }} dari total {{ page.itemsLength }} data
          </template>
        </v-data-iterator>
      </v-tab-item>
    </v-tabs-items>

    <customer-form
      ref="customerForm"
      @success="dataStateChange({ skip: pagination.skip, take: pagination.take })"
    />

    <import-customer
      ref="importCustomer"
      @success="dataStateChange({ skip: pagination.skip, take: pagination.take })"
    />

    <customer-filter-builder
      ref="customerFilterBuilder"
      @close="dataStateChange({ skip: 0, take: pagination.take })"
    />
  </v-card>
</template>

<script>
import { getVuetify } from '@core/utils'
import {
  ref, computed, onMounted, watch,
} from '@vue/composition-api'
import {
  mdiClose,
  mdiMagnify,
  mdiTable,
  mdiCardText,
  mdiPlus,
  mdiRefresh,
  mdiArchive,
  mdiChevronDown,
  mdiPlusCircleOutline,
} from '@mdi/js'
import { createFieldMapper } from 'vuex-use-fields'
import { useStorage, useDebounceFn } from '@vueuse/core'
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import { apolloClient } from '@/vue-apollo'
import { customers } from '@/graphql/queries'
import InputIconToggle from '@/components/inputs/InputIconToggle.vue'
import SplitButton from '@/components/inputs/SplitButton.vue'
import useCustomer from './useCustomer'
import CustomerForm from './CustomerForm.vue'
import ImportCustomer from './ImportCustomer.vue'
import CustomerFilterBuilder from './CustomerFilterBuilder.vue'
import CustomerSavedView from './CustomerSavedView.vue'
import CustomerCard from './CustomerCard.vue'
import ArchivedCustomer from './ArchivedCustomer.vue'
import useCustomAttributes from '@/composables/useCustomAttributes'
import dateFormat from '@/utils/dateFormat'
import useSavedView from '@/composables/useSavedView'
import {
  Page,
  Sort,
  Group,
  Freeze,
  Resize,
  Reorder,
  PdfExport,
  ExcelExport,
  Toolbar,
  Edit,
  ColumnChooser,
  LazyLoadGroup,
  ContextMenu,
} from '@syncfusion/ej2-vue-grids'
// eslint-disable-next-line import/no-extraneous-dependencies
import { enableRipple } from '@syncfusion/ej2-base'
import TypeColumn from './columns/TypeColumn.vue'
import SelectMultipleColumn from './columns/SelectMultipleColumn.vue'
import NameColumn from './columns/NameColumn.vue'
import errorHandling from '@/utils/errorHandling'
import router from '@/router'

const useFieldCustomer = createFieldMapper({ getter: 'customer/getField', setter: 'customer/setField' })

export default {
  components: {
    InputIconToggle,
    SplitButton,
    CustomerForm,
    ImportCustomer,
    CustomerCard,
    ArchivedCustomer,
    CustomerFilterBuilder,
    CustomerSavedView,
  },
  provide: {
    grid: [
      Page,
      Sort,
      Group,
      Freeze,
      Resize,
      Reorder,
      PdfExport,
      Toolbar,
      ExcelExport,
      Edit,
      ColumnChooser,
      LazyLoadGroup,
      ContextMenu,
    ],
  },
  setup() {
    const $vuetify = getVuetify()
    const state = {
      ...useFieldCustomer([
        'customers',
        'customersCount',
        'customerFilter',
        'headerSort',
        'sort',
        'search_field',
      ]),
    }
    const {
      customAttributesArray,
      fetchCustomAttributes,
    } = useCustomAttributes({ objectTypeId: 4 })
    const {
      listSavedViews,
      listSavedDefaultViews,
      fetchSavedViews,
      addNewView,
      editView,
    } = useSavedView({ objectTypeId: 4 })
    const { delCustomer } = useCustomer()

    const isArchiveOpen = ref(false)
    const search = ref('')
    const viewTab = useStorage('viewTabCustomer', 0)
    const loading = ref(false)
    const menu = ref(false)
    const headers = ref([])
    const sortOptions = [
      {
        label: 'Nama Pelanggan A-Z',
        sort: 'name',
        order: 'ASC',
      },
      {
        label: 'Nama Pelanggan Z-A',
        sort: 'name',
        order: 'DESC',
      },
      {
        label: 'Aktivitas Terakhir Terlama',
        sort: 'last_activity_at',
        order: 'ASC',
      },
      {
        label: 'Aktivitas Terakhir Terdekat',
        sort: 'last_activity_at',
        order: 'DESC',
      },
    ]
    const customerForm = ref(null)
    const customerSavedView = ref(null)

    enableRipple(true) // enable ripple effect on syncfusion components
    const pagination = ref({})
    const customerGrid = ref(null)
    const customerList = ref(null)

    // column template
    const nameColumn = () => ({ template: NameColumn })
    const typeColumn = () => ({ template: TypeColumn })
    const selectMultipleColumn = () => ({
      template: {
        extends: SelectMultipleColumn,
        propsData: { customAttributes: customAttributesArray.value },
      },
    })

    // value accessor
    const tagAccess = (field, data) => data[field].map(el => el.name).sort((a, b) => a.localeCompare(b)).join(', ')
    const singleAccess = (field, data) => {
      const customAttributeId = Number(field.split('.', 2)[1])

      if (
        data.custom_attribute_values === null
        || data.custom_attribute_values[customAttributeId] === undefined
      ) {
        return ''
      }

      const customAttribute = customAttributesArray.value.find(el => el.custom_attribute.id === customAttributeId).custom_attribute

      return (customAttribute.options.find(el => el.id === Number(data.custom_attribute_values[customAttributeId])).name)
    }
    const multipleAccess = (field, data) => {
      const customAttributeId = Number(field.split('.', 2)[1])

      if (
        data.custom_attribute_values === null
        || data.custom_attribute_values[customAttributeId] === undefined
      ) {
        return ''
      }

      const customAttribute = customAttributesArray.value.find(el => el.custom_attribute.id === customAttributeId).custom_attribute

      return JSON.parse(data.custom_attribute_values[customAttributeId]).map(el => customAttribute.options.find(opt => opt.id === el).name)
    }

    // static columns properties
    const columns = ref([
      {
        field: 'name',
        dataTypeId: null,
        headerText: 'Nama',
        width: '350',
        textAlign: 'Left',
        showInColumnChooser: false,
        allowReordering: false,
        allowGrouping: false,
        isFrozen: true,
        template: nameColumn,
      },
      {
        field: 'phone_number',
        dataTypeId: null,
        headerText: 'No Telepon',
        width: '200',
        textAlign: 'Left',
      },
      {
        field: 'gender',
        dataTypeId: null,
        headerText: 'Gender',
        width: '130',
        textAlign: 'Left',
      },
      {
        field: 'description',
        dataTypeId: null,
        headerText: 'Deskripsi',
        width: '200',
        textAlign: 'Left',
        clipMode: 'EllipsisWithTooltip',
      },
      {
        field: 'type',
        dataTypeId: null,
        headerText: 'Tags',
        width: '580',
        textAlign: 'Left',
        allowSorting: false,
        allowEditing: false,
        valueAccessor: tagAccess,
        template: typeColumn,
      },
      {
        field: 'address',
        dataTypeId: null,
        headerText: 'Alamat',
        width: '200',
        textAlign: 'Left',
      },
      {
        field: 'job_title',
        dataTypeId: null,
        headerText: 'Pekerjaan',
        width: '200',
        textAlign: 'Left',
      },
      {
        field: 'whatsapp_number',
        dataTypeId: null,
        headerText: 'No Whatsapp',
        width: '200',
        textAlign: 'Left',
      },
      {
        field: 'facebook',
        dataTypeId: null,
        headerText: 'Facebook',
        width: '200',
        textAlign: 'Left',
      },
      {
        field: 'city.name',
        dataTypeId: null,
        headerText: 'Kota Asal',
        width: '200',
        textAlign: 'Left',
        allowSorting: false,
      },
      {
        field: 'birth_date',
        dataTypeId: null,
        headerText: 'Tanggal Lahir',
        width: '200',
        textAlign: 'Left',
        type: 'date',
        format: { type: 'date', format: 'dd MMMM yyyy' },
        editType: 'datepickeredit',
        edit: "{ params: { format:'dd.MM.yy' }",
      },
    ])
    const pageSettings = ref(
      {
        pageSizes: [20, 40, 80],
        pageSize: 20,
        pageCount: 4,
        currentPage: 1,
      },
    )
    const editSettings = ref({
      allowEditing: true,
      allowDeleting: true,
      mode: 'Dialog',
    })
    const groupSettings = ref({
      enableLazyLoading: false,
    })
    const contextMenuItems = ref([
      'AutoFit', 'AutoFitAll',
      'PdfExport', 'ExcelExport',
      'SortAscending', 'SortDescending',
      'Copy', 'Edit', 'Delete', 'Save', 'Cancel',
      'FirstPage', 'PrevPage', 'LastPage', 'NextPage',
    ])
    const toolbarOptions = ref([
      'PdfExport',
      'ExcelExport',
      'Edit',
      'ColumnChooser',
      {
        text: 'Saved Views',
        tooltipText: 'Tampilan Tabel',
        prefixIcon: 'e-expand',
        id: 'saved_views',
        align: 'right',
      },
    ])

    const initLayout = () => {
      // TODO: implement apply default saved view on no saved view yet
      const gridColumns = listSavedViews.value.find(el => el.is_selected).fields
      columns.value = gridColumns.map(el => ({
        ...columns.value[el.index],
        width: el.width,
        visible: el.visible,
        index: el.index,
      }))
    }

    const flag = ref(true) // flag for dataBound
    const dataBound = () => {
      if (flag.value === true) {
        flag.value = false

        // push custom attributes columns properties
        customAttributesArray.value.forEach(el => {
          if ([1, 2].includes(el.custom_attribute.data_type.id)) {
            columns.value.push({
              field: `custom_attribute_values.${el.custom_attribute.id}`,
              headerText: el.custom_attribute.name,
              width: '200',
              textAlign: 'Left',
              dataTypeId: el.custom_attribute.data_type.id,
            })
          } else if (el.custom_attribute.data_type.id === 3) {
            columns.value.push({
              field: `custom_attribute_values.${el.custom_attribute.id}`,
              headerText: el.custom_attribute.name,
              width: '200',
              textAlign: 'Left',
              type: 'date',
              format: { type: 'date', format: 'dd MMMM yyyy' },
              editType: 'datepickeredit',
              edit: "{ params: { format:'dd.MM.yy' }",
              dataTypeId: el.custom_attribute.data_type.id,
            })
          } else if (el.custom_attribute.data_type.id === 4) {
            columns.value.push({
              field: `custom_attribute_values.${el.custom_attribute.id}`,
              headerText: el.custom_attribute.name,
              width: '200',
              textAlign: 'Left',
              valueAccessor: singleAccess,
              dataTypeId: el.custom_attribute.data_type.id,
            })
          } else if (el.custom_attribute.data_type.id === 5) {
            columns.value.push({
              field: `custom_attribute_values.${el.custom_attribute.id}`,
              headerText: el.custom_attribute.name,
              width: '200',
              textAlign: 'Left',
              valueAccessor: multipleAccess,
              allowSorting: false,
              template: selectMultipleColumn,
              dataTypeId: el.custom_attribute.data_type.id,
            })
          }
        })

        // saved views initial load
        initLayout()
      }

      // TODO: implement finish loading
    }

    const filterCount = computed(() => store.getters['customer/filterCount'])

    const saveLayout = async (viewName, viewId) => {
      const currentProperties = JSON.parse(customerGrid.value.ej2Instances.getPersistData()).columns
      const currentColumns = [...customerGrid.value.ej2Instances.getColumns()]
      const savedColumns = currentProperties.map(el => {
        const {
          headerText, visible, index, width, dataTypeId,
        } = currentColumns.find(colColumnsState => colColumnsState.field === el.field)

        return {
          headerText,
          visible,
          index,
          width,
          dataTypeId,
        }
      })

      // TODO: handle submit edit for default saved view
      if (viewId) {
        await editView(savedColumns, viewName, viewId)
      } else {
        await addNewView(savedColumns, viewName)
      }
      await fetchSavedViews()
    }

    const restoreLayout = savedColumns => {
      const currentProperties = JSON.parse(customerGrid.value.ej2Instances.getPersistData()).columns
      const staticProperties = {
        0: { template: nameColumn },
        4: { template: typeColumn, valueAccessor: tagAccess },
      }
      const customProperties = {
        4: { valueAccessor: singleAccess },
        5: { template: selectMultipleColumn, valueAccessor: multipleAccess },
      }

      const newColumns = savedColumns.map(el => {
        const idx = currentProperties.findIndex(col => col.index === el.index)

        return {
          ...currentProperties[idx],
          ...el,
          ...(staticProperties[el.index] || {}),
          ...(customProperties[el.dataTypeId] || {}),
        }
      })

      /* TODO: implement update saved views on custom attributes update */
      if (newColumns.length !== currentProperties.length) {
        // push new custom attributes column to current instance

        // update saved views field with new columns added
      }

      customerGrid.value.ej2Instances.setProperties({ columns: newColumns })
    }

    const fetchCustomers = () => {
      const filter = state.customerFilter.value
      loading.value = true

      return new Promise((resolve, reject) => {
        apolloClient.query({
          query: customers,
          variables: {
            pagination: {
              limit: pagination.value.take,
              offset: pagination.value.skip,
            },
            filter: {
              custom_filter: filter.custom_filter.length ? filter.custom_filter.map(data => ({
                attribute_id: data.fieldInput.id,
                data_type_id: data.fieldInput.data_type.id,
                operator: data.operator,
                match_type: data.match_type,
                condition: data.condition,
                value: data.value?.toString(),
              })) : null,
              customer_type: filter.customer_type.length ? filter.customer_type.map(data => ({
                operator: data.operator,
                match_type: data.match_type,
                value: data.value,
                condition: data.condition,
              })) : null,
              customer_city: filter.customer_city.length ? filter.customer_city.map(data => ({
                operator: data.operator,
                value: data.value.id,
                condition: data.condition,
              })) : null,
              sort: state.headerSort.value.length ? state.headerSort.value.map(item => ({ sort: item.sort, order: item.order })) : { sort: state.sort.value.sort, order: state.sort.value.order },
              search_field: state.search_field.value.filter(el => el.value).map(el => el.field),
            },
            search: search.value,
            workspace_id: store.getters.getCurrentWorkspaceId,
          },
          fetchPolicy: 'no-cache',
        }).then(async result => {
          store.commit('customer/setField', { location: 'customers', value: result.data.customers.customers })
          store.commit('customer/setField', { location: 'customersCount', value: result.data.customers.count })
          await fetchCustomAttributes()
          resolve({
            result: result.data.customers.customers,
            count: result.data.customers.count,
          })
          loading.value = false
        }).catch(err => {
          loading.value = false
          router.push('/error')
          reject(err)
          errorHandling(err, 'Daftar Pelanggan')
        })
      })
    }

    const dataStateChange = stateArgs => {
      pagination.value = {
        skip: stateArgs.skip,
        take: stateArgs.take,
      }

      // console.log(stateArgs)
      // console.log(customerGrid.value)

      // handle sorting
      if (stateArgs.group) {
        state.headerSort.value = stateArgs.sorted.map(el => ({ sort: el.name.includes('custom_attribute_values') ? `custom_attribute_values->>'$."${el.name.split('.', 2)[1]}"'` : el.name, order: el.direction === 'ascending' ? 'ASC' : 'DESC' }))
        pagination.value = {
          skip: 0,
          take: state.customersCount.value,
        }
      } else if (stateArgs.sorted) {
        state.headerSort.value = stateArgs.sorted.map(el => ({ sort: el.name.includes('custom_attribute_values') ? `custom_attribute_values->>'$."${el.name.split('.', 2)[1]}"'` : el.name, order: el.direction === 'ascending' ? 'ASC' : 'DESC' }))
      } else if (!stateArgs.sorted && stateArgs.action?.requestType === 'sorting') {
        state.headerSort.value = []
      }

      fetchCustomers().then(data => { customerList.value = data })
    }

    const searchCustomer = useDebounceFn(() => {
      dataStateChange({ skip: 0, take: pagination.value.take })
    }, 1000)

    const deleteCustomer = id => {
      delCustomer(id).then(() => {
        dataStateChange({ skip: pagination.value.skip, take: pagination.value.take })
      })
    }

    /* eslint-disable no-param-reassign */
    const pdfQueryCellInfo = args => {
      if (args.column.field === 'name') {
        args.style = {
          backgroundColor: '#99ffcc',
        }
      }
    }
    /* eslint-enable no-param-reassign */

    const handleToolbarClick = args => {
      if (args.item.id === 'customerGrid_pdfexport') { // 'Grid_pdfexport' -> Grid component id + _ + toolbar item name
        customerGrid.value.pdfExport()
      } else if (args.item.id === 'customerGrid_excelexport') {
        customerGrid.value.excelExport()
      } else if (args.item.id === 'saved_views') {
        customerSavedView.value.show()
      }
    }

    const handleActionComplete = args => {
      console.log('complete', args)

      /* if (args.requestType === 'sorting') {
        customerGrid.value.ej2Instances.groupCollapseAll()
      } */
    }

    const handleActionBegin = args => {
      // console.log('begin', args.requestType)
      /* eslint-disable no-param-reassign */
      switch (args.requestType) {
        case 'beginEdit':
          args.cancel = true
          customerForm.value.update(args.rowData.id)
          break
        case 'delete':
          args.cancel = true
          deleteCustomer(args.data[0].id)
          break
        case 'grouping':
          args.cancel = true
          // console.log(args)
          customerGrid.value.ej2Instances.getColumnByField('name').isFrozen = false
          customerGrid.value.ej2Instances.refreshColumns()
          customerGrid.value.groupColumn(args.columnName)
          customerGrid.value.sortColumn(args.columnName, 'Ascending')
          break
        case 'ungrouping':
          customerGrid.value.ej2Instances.getColumnByField('name').isFrozen = true
          customerGrid.value.ej2Instances.refreshColumns()
          customerGrid.value.ungroupColumn(args.columnName)
          customerGrid.value.clearSorting()
          break
        default:
          break
      }
      /* eslint-enable no-param-reassign */
    }

    const setTheme = () => {
      if ($vuetify.theme.dark) {
        document.getElementById('theme').href = '/syncfusion-theme/grid/ej2-grid-dark.css'
      } else {
        document.getElementById('theme').href = '/syncfusion-theme/grid/ej2-grid-light.css'
      }
    }

    watch($vuetify, () => {
      setTheme()
    })

    onMounted(() => {
      setTheme()
      fetchSavedViews()
      fetchCustomAttributes()
      dataStateChange({ skip: 0, take: 20 })
    })

    return {
      isArchiveOpen,
      contextMenuItems,
      headers,
      menu,
      search,
      viewTab,
      state,
      loading,
      pagination,
      customAttributesArray,
      listSavedViews,
      listSavedDefaultViews,
      filterCount,
      sortOptions,
      customerList,
      customerGrid,
      columns,
      pageSettings,
      editSettings,
      toolbarOptions,
      customerForm,
      customerSavedView,
      groupSettings,

      fetchCustomers,
      fetchSavedViews,
      deleteCustomer,
      searchCustomer,
      avatarText,
      fetchCustomAttributes,
      dateFormat,
      tagAccess,
      handleToolbarClick,
      dataBound,
      dataStateChange,
      handleActionBegin,
      handleActionComplete,
      saveLayout,
      pdfQueryCellInfo,
      restoreLayout,

      icons: {
        mdiPlusCircleOutline,
        mdiClose,
        mdiMagnify,
        mdiTable,
        mdiCardText,
        mdiPlus,
        mdiRefresh,
        mdiArchive,
        mdiChevronDown,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';

.e-content {
  @include style-scroll-bar();
  overflow-x: scroll;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    background: none;
    padding-top: 16px;
  }
}

.grid-container:after,
.grid-container::after {
  clear: both;
  content: "";
  display: table;
}
</style>
